
import React, { useCallback, useEffect, useState } from 'react';

import { requestData } from './request';
import { NodeIndexOutlined, UploadOutlined } from '@ant-design/icons';
import { Input, Spin, Upload, Tabs, Descriptions, Select, Form, Progress, Button, message, Space, Table, Tag, Modal } from 'antd';
import { Line } from '@ant-design/charts';
// import { partial } from 'filesize';
import fileSize from 'filesize';

import moment from 'moment';

import { Segment } from './segment';

// const fileSize = partial({ standard: 'jedec' });

async function loadData() {
  const res = await requestData('getAudios');
  return res;
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const columns = [{
  title: 'audioId',
  key: 'audioId',
  dataIndex: 'audioId'
}, {
  title: 'site',
  key: 'site',
  dataIndex: 'site'
}, {
  title: 'title',
  key: 'title',
  dataIndex: 'title'
}, {
  title: 'duration(s)',
  key: 'duration',
  dataIndex: 'duration'
}, {
  title: 'translateTaskId',
  key: 'translateTaskId',
  dataIndex: 'translateTaskId'
}, {
  title: 'originOssPath',
  key: 'originOssPath',
  dataIndex: 'originOssPath'
}, {
  title: 'podcastId',
  key: 'podcastId',
  dataIndex: 'podcastId'
}, {
  title: 'taskId',
  key: 'taskId',
  dataIndex: 'taskId'
}, {
  title: 'action',
  key: 'action',
  render: (item) => {
    return (
      <>
        <Button type='link' onClick={() => onTranslate(item)}>translate</Button>
        <Button type='link' onClick={() => onSegment(item)}>segment</Button>
        <Button type='link' onClick={() => onBuilder(item)}>build</Button>
      </>
    )
  }
}];

async function onBuilder(item) {
  let allFormValue = {};
  function onChange(currentItem, totalItem) {
    allFormValue = totalItem;
  }
  const result = await Modal.confirm({
    title: 'build',
    content: (
      <Form onValuesChange={onChange}>
        <Form.Item label="voice" name="voice">
          <Select>
            <Select.Option value="kuailexiaodong">快乐小东</Select.Option>
            <Select.Option value="wennuanahu">温暖阿虎</Select.Option>
            <Select.Option value="meilisufei">魅力苏菲</Select.Option>
            <Select.Option value="wenroutaozi">温柔桃子</Select.Option>
            <Select.Option value="shuangkuaisisi">爽快思思</Select.Option>
            <Select.Option value="youyoujunzi">悠悠君子</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    ),
    onOk: async () => {
      await requestData('generateTask', {
        audioId: item.audioId,
        type: 'TRANSLATE',
        title: item.title,
        params: {
          audioId: item.audioId,
          datetime: item.site === 'cxdt' ? item.title : undefined,
          ...allFormValue
        }
      });
    }
  });
}

async function onTranslate(item) {
  const result = window.confirm(`do you want to translate: [${item.title}]`);
  if (true === result) {
    await requestData('translateAudio', {
      audioId: item.audioId
    });
  }
}

function RenderCNContent({item}) {
  const [content, setContent] = useState(item.cnContent);
  async function onUpdateCnContent() {
    await requestData('updateSegment', {
      cnContent: content,
      audioId: item.audioId,
      segmentId: item.segmentId
    });
  }

  async function onRebuildSegment() {
    await requestData('rebuildSegment', {
      audioId: item.audioId,
      segmentId: item.segmentId
    });
  }

  return (
    <>
      <Button type="primary" onClick={onUpdateCnContent}>update</Button>
      <Button type="primary" onClick={onRebuildSegment}>rebuild</Button>
      <Input.TextArea className='audio-cn-content-textarea' value={content} onChange={(e) => setContent(e.currentTarget.value)} />
    </>
  );
}

async function onSegment(item) {
  Modal.confirm({
    content: <Segment audioId={item.audioId} />,
    width: 1300
  });
}

async function onUpdateTranslate(item, content) {

  await requestData('updateTranslateResult', {
    audioId: item.audioId,
    translate: JSON.parse(content)
  });

  message.success('success');

}

function RenderContent({ item }) {

  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');

  const loadData = useCallback( async () => {
    setLoading(true);

    try {
      const data = await requestData('getTranslateResult', {
        audioId: item.audioId
      });
      setContent(JSON.stringify(data, null, 4));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Button type='primary' onClick={() => { onUpdateTranslate(item, content) }}>update translate</Button>
      <Input.TextArea className='audio-content' onChange={(e) => { setContent(e.currentTarget.value) }} value={content} />
    </>
  )
}

function RenderList({listData}) {

  return (
    <Table dataSource={listData} columns={columns} pagination={false} expandable={{
      rowExpandable: () => true,
      expandedRowRender: (item) => {
        return <RenderContent item={item} />
      }
    }} />
  );
}

function UploadForm(props) {

  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onValuesChange = useCallback((currentItem, totalItem) => {
    props.onValuesChange(currentItem, totalItem);
  }, []);

  const customRequest = useCallback((option) => {
    option.onSuccess();
  }, []);

  const onUploadChange = useCallback(async (option) => {
    // const base64 = await getBase64(option.file);
  }, []);

  return (
    <div>
      <Form preserve={false} onValuesChange={onValuesChange}>
        <Form.Item label="type" name="type" initialValue='bookClub'>
          <Select>
            <Select.Option value="bookClub">Nick's Book Club</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="audio" name="audio">
          <Input />
        </Form.Item>
        <Form.Item label="author" name="author">
          <Input />
        </Form.Item>
        <Form.Item label="bookName" name="bookName">
          <Input />
        </Form.Item>
      </Form>
    </div>
  )
}

export default () => {

  const [listData, setListData] = useState([]);
  const [items, setItems] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      setLoading(false);
      let i = 0;
      const itemMap = {};
      for (const item of data) {
        item.key = i++;
        const { site } = item;
        if (itemMap[site] === undefined) {
          itemMap[site] = [];
        }
        itemMap[site].push(item);
      }
      const keys = Object.keys(itemMap);
      const newItems = [];
      for (const key of keys) {
        newItems.push({
          key: key,
          label: key,
          children: <RenderList listData={itemMap[key].reverse()} />
        });
      }
      setItems(newItems);
    }).catch((e) => {
      console.error(e);
      setLoading(false);
    });
  }, []);

  const onAddAudio = useCallback(() => {

    let formValues = {};

    Modal.confirm({
      content: (
        <div>
          <UploadForm onValuesChange={async (currentValue, totalValue) => {
            if (true === Array.isArray(totalValue?.audio)) {
              const audio = totalValue.audio[0];
              totalValue.audio = await getBase64(audio.originFileObj);
              const name = audio?.name || '';
              const suffixItems = name.split('.')?.reverse() || [];
              totalValue.suffix = suffixItems[0];
            }
            formValues = totalValue;
            console.log('total values: ', totalValue);
          }} />
        </div>
      )
      ,
      width: 500,
      onOk: async () => {
        await requestData('uploadAudio', formValues);
        message.success('upload audio success');
      }
    });
  }, []);

  return (
    <div className="articles">
      <div>
        <Button type='primary' onClick={onAddAudio}>Add Audio</Button>
      </div>
      <Spin spinning={loading}>
        <Tabs items={items}></Tabs>
      </Spin>
    </div>
  );
}
